import { USER_LOADED, LOGIN_SUCCESS, LOGOUT, AUTH_ERROR } from "../actions/types";
import {setToken, getCookie, setCookie, eraseCookie} from '../utility/config';

const initialState = {
//   token: localStorage.getItem('token'),
  token: getCookie('token'),
  isAuthenticated: false,
  loading: true,
  user: null,
  roles: null,
};


export default function auth (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: payload,
                roles: payload.role,
            };
        case LOGIN_SUCCESS:
            // localStorage.setItem("token", payload.access_token);
            setCookie('token', payload.access_token, 1);
            setToken(payload.access_token);
            return {
                ...state,
                token: payload,
                isAuthenticated: true,
                loading: false,
            };
        case AUTH_ERROR:
        case LOGOUT:
            // localStorage.removeItem("token");
            eraseCookie('token');
            setToken(null);
            return {
                ...state,
                token: null,
                user: null,
                isAuthenticated: false,
                loading: false,
            };
        default:
            return state;
    }
}